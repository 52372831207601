import React from "react"
import Text from "./RevealingText"
const Footer = () => {
  let d = new Date()
  let currentYear = d.getFullYear()
  return (
    <>
      <footer className="footer">
        <div className="footer-content mb-48">
          <div className="footer-content--message">
            <h2 className="section-heading text-stroked-black">
              Let's work together.
            </h2>
            <p>
              Have a project in mind? Email us at &nbsp;
              <a className="text-white" href="mailto:contact@kubo.co.in">
                contact@kubo.co.in
              </a>
            </p>
          </div>
          <div className="footer-content--about">
            <div className="logo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 645.999 711">
                <defs>
                  {/* <linearGradient
                  id="linear-gradient"
                  x1="1"
                  x2="0"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" />
                  <stop offset="1" stop-color="#292828" />
                </linearGradient> */}
                </defs>
                <path
                  id="Union_110"
                  data-name="Union 110"
                  d="M323,549.406c0-89.242,72.3-161.588,161.5-161.588S646,460.164,646,549.406,573.691,711,484.5,711,323,638.652,323,549.406ZM161.5,710.984V711H0V387.818H161.5v.014c.537-.014,1.074-.014,1.612-.014a95.347,95.347,0,0,1,68.52,161.588A95.349,95.349,0,0,1,163.113,711Q162.306,711,161.5,710.984ZM323,161.59V0H646V161.59c0,89.242-72.3,161.592-161.5,161.592S323,250.832,323,161.59ZM0,323.182V0H258.405L122.22,159.961,258.405,323.182Z"
                  transform="translate(0 0.002)"
                />
              </svg>
            </div>
            <p>
              KUBO, an innovative web agency, helping business and brand with an
              extraordinary web experiences.
            </p>
          </div>
        </div>
        <div className="footer-contact">
          <div className="footer-contact--email">
            <a className="text-white" href="mailto:contact@kubo.co.in">
              contact@kubo.co.in
            </a>
          </div>
          <div className="footer-contact--phone">
            <a className="text-white" href="tel:+919693943540">
              +91-9693943540
            </a>
          </div>
          <div className="footer-contact--social">
            <a
              className="pageLinks text-white"
              href="https://www.facebook.com/kubowebagency"
            >
              fb
            </a>
            <a
              className="pageLinks text-white"
              href="https://twitter.com/KuboWeb"
            >
              tw
            </a>
            <a
              className="pageLinks text-white"
              href="https://www.instagram.com/kubo_web_agency/"
            >
              in
            </a>
          </div>
        </div>
        <p className="footerCopyright">
          All Rights Reserved &copy; {currentYear}, KUBO
        </p>
      </footer>
    </>
  )
}

export default Footer
