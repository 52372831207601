import React, { useRef } from "react"
import Theme from "../components/Theme"
import { Link } from "gatsby"
import Scroll from "./ScrollIndicator"
const Header = () => {
  const menu = useRef()
  const navBar = useRef()
  const menuBtn = useRef()
  const handleMenu = () => {
    menu.current.classList.toggle("menu-active")
    menuBtn.current.classList.toggle("menuBtn-active")
    navBar.current.classList.toggle("navbar-color-change")
  }

  return (
    <>
      <nav className="navbar">
        <Theme />
        <ul className="navbar-list" ref={navBar}>
          <Scroll />
          <li className="navbar-list--items">
            <Link to="/" aria-label="Home">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="header-logo"
                viewBox="0 0 645.999 711"
              >
                <defs>
                  <linearGradient
                    id="linear-gradient"
                    x1="1"
                    x2="0"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stopColor="#1678ff" />
                    <stop offset="1" stopColor="#00ffc6" />
                  </linearGradient>
                </defs>
                <path
                  id="Union_104"
                  data-name="Union 104"
                  d="M323,549.406c0-89.242,72.3-161.588,161.5-161.588S646,460.164,646,549.406,573.691,711,484.5,711,323,638.652,323,549.406ZM161.5,710.984V711H0V387.818H161.5v.014c.537-.014,1.074-.014,1.612-.014a95.347,95.347,0,0,1,68.52,161.588A95.349,95.349,0,0,1,163.113,711Q162.306,711,161.5,710.984ZM323,161.59V0H646V161.59c0,89.242-72.3,161.592-161.5,161.592S323,250.832,323,161.59ZM0,323.182V0H258.405L122.22,159.961,258.405,323.182Z"
                  transform="translate(0 0.002)"
                  fill="url(#linear-gradient)"
                />
              </svg>
            </Link>
          </li>

          <li
            className="navbar-list--items ham-menu"
            onClick={handleMenu}
            onKeyDown={handleMenu}
            role="presentation"
            tabIndex="-1"
          >
            <button
              className="ham-menu-btn"
              ref={menuBtn}
              aria-label="Menu"
              title="Menu"
            >
              <span>
                MENU &nbsp;
                <span>
                  <svg
                    className="menu-btn-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                  >
                    <g
                      id="Group_86"
                      data-name="Group 86"
                      transform="translate(-12750 -10672)"
                    >
                      <circle
                        id="Ellipse_153"
                        className="menu-circle"
                        data-name="Ellipse 153"
                        cx="15"
                        cy="15"
                        r="15"
                        transform="translate(12750 10672)"
                      />
                      <line
                        id="Line_22"
                        className="menu-btn-line"
                        data-name="Line 22"
                        x2="10"
                        transform="translate(12765 10682)"
                        fill="none"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_23"
                        className="menu-btn-line"
                        data-name="Line 23"
                        x2="20"
                        transform="translate(12755 10687)"
                        fill="none"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_24"
                        className="menu-btn-line"
                        data-name="Line 24"
                        x2="15"
                        transform="translate(12760 10692)"
                        fill="none"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </span>
              </span>
            </button>
          </li>
        </ul>
      </nav>
      <div className="navbar-list--items main-menu" ref={menu}>
        <div className="main-menu--items">
          <Link to="/" className="link-item glitch" data-word="Home">
            Home
          </Link>
        </div>
        <div className="main-menu--items">
          <Link to="/project" className="link-item glitch" data-word="Projects">
            Projects
          </Link>
        </div>
        <div className="main-menu--items">
          <Link
            to="/articles"
            className="link-item glitch"
            data-word="Articles"
          >
            Articles
          </Link>
        </div>
        <div className="main-menu--items">
          <Link to="/contact" className="link-item glitch" data-word="Contact">
            Contact
          </Link>
        </div>
        <div className="menu-overlay menu-overlay--right"></div>
        <div className="menu-overlay menu-overlay--left"></div>
      </div>
    </>
  )
}

export default Header
