import React, { useEffect } from "react"
const RevealAnimation = ({ children }) => {
  useEffect(() => {
    const elementsToAnimateArray = Array.from(
      document.querySelectorAll(".elToAnimate-js")
    )

    const imagesToAnimateArray = Array.from(
      document.querySelectorAll(".imagesToAnimate-js")
    )

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.6,
    }

    let textReveal = (entries, observer) => {
      entries.forEach(entry => {
        const hasClass = entry.target.classList.contains("animate-in")
        if (entry.isIntersecting && !hasClass) {
          entry.target.classList.add("animate-in")
        }
      })
    }

    let imageReveal = (entries, observer) => {
      entries.forEach(entry => {
        const imageHasClass = entry.target.classList.contains(
          "animate-in-image"
        )
        if (entry.isIntersecting && !imageHasClass) {
          entry.target.classList.add("animate-in-image")
        }
      })
    }

    let textObserver = new IntersectionObserver(textReveal, options)
    let imageObserver = new IntersectionObserver(imageReveal, options)
    elementsToAnimateArray.forEach(el => {
      textObserver.observe(el)
    })
    imagesToAnimateArray.forEach(el => {
      imageObserver.observe(el)
    })
    return () => {
      elementsToAnimateArray.forEach(el => {
        textObserver.unobserve(el)
      })
      imagesToAnimateArray.forEach(el => {
        imageObserver.unobserve(el)
      })
    }
  }, [])
  return <>{children}</>
}

export default RevealAnimation
