import React, { useRef, useState, useEffect } from "react"
import _ from "lodash"

const ScrollIndicator = () => {
  const target = useRef()
  const [scroll, setScroll] = useState(0)
  useEffect(() => {
    const onScroll = () => {
      const totalScroll = document.documentElement.scrollTop
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      const scale = `${totalScroll / windowHeight}`
      setScroll(scale)
    }

    const throttleScroll = _.throttle(onScroll, 250)

    window.addEventListener("scroll", throttleScroll)
    return () => {
      window.removeEventListener("scroll", throttleScroll)
    }
  }, [])
  return (
    <li
      className="scrollIndicator"
      ref={target}
      style={{ transform: `scaleX(${scroll})` }}
    ></li>
  )
}

export default ScrollIndicator
