import React from "react"
import Header from "./Header"
import Footer from "./Footer"
// import SmoothScroll from "./SmoothScroll"
import Animation from "./RevealAnimation"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {/* <SmoothScroll> */}
      <main>
        <Animation>{children}</Animation>
      </main>
      <Footer></Footer>
      {/* </SmoothScroll> */}
    </>
  )
}

export default Layout
